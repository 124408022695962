<template>
  <v-card
    color="#F4F9FF"
    style="display:flex; flex-direction: row; height: 100%; width: 100%"
  >
    <v-layout style="display: flex; flex-direction: column;">
      <label class="item-title">Variables</label>
      <label class="email-title ml-10 mb-5">
        You can add the default variables for questions and intakes.
      </label>
      <div class="d-flex ml-10" style="height: 100%; overflow: hidden">
        <div style="width: 400px; height: 100%; overflow-y: scroll">
          <div v-for="setting in settings" :key="setting.title">
            <div
              class="pa-3 key-item d-flex flex-row align-center"
              :style="
                setting._id == selected
                  ? 'background: #f3e9ff; justify-content: space-between;'
                  : ''
              "
              @click="onSelectKey(setting)"
            >
              {{ setting.title }}
              <v-btn
                icon
                v-if="setting._id == selected"
                @click="onDeleteKey(setting)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </div>
          </div>
        </div>
        <div style="flex-grow:1; height: 100%; overflow-y: scroll;">
          <div
            style="width: 100%; justify-content:center; display:flex;"
            v-if="isValueLoading"
          >
            <v-progress-circular indeterminate color="primary" />
          </div>
          <div v-for="value in values" :key="value._id">
            <div
              class="pa-3 key-item d-flex flex-row align-center"
              :style="
                value._id == selectedValue
                  ? 'background: #f3e9ff;justify-content: space-between;'
                  : ''
              "
              @click="onSelectValue(value)"
            >
              {{ value.text }}
              <div>
                <v-btn
                  icon
                  v-if="value._id == selectedValue"
                  @click="openEditValueDialog(value)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  icon
                  v-if="value._id == selectedValue"
                  @click="onDeleteValue(value)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="display:flex; justify-content:flex-end; flex-direction: row;">
        <v-btn
          rounded
          width="200"
          color="primary"
          class="mr-3 my-5"
          @click="openNewKeyDialog"
          :loading="loading"
          v-text="'Add New Key'"
        />
        <v-btn
          rounded
          width="200"
          color="primary"
          class="mr-3 my-5"
          @click="openNewValueDialog"
          :loading="loading"
          v-text="'Add New Value'"
        />
      </div>
    </v-layout>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog v-model="addSettingDialog" width="500px" persistent>
      <v-card>
        <v-card-title style="color: #6e3dc6; font-family: 'Poppins-Bold'; "
          >Add New Key</v-card-title
        >
        <v-card-text>
          <v-text-field v-model="new_key" :error-messages="newKeyMessage" />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn @click="onAddNewSetting" rounded color="primary" small
            >Add</v-btn
          >
          <v-btn @click="addSettingDialog = false" rounded small>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addValueDialog" width="500px" persistent>
      <v-card>
        <v-card-title style="color: #6e3dc6; font-family: 'Poppins-Bold'; "
          >Add New Value</v-card-title
        >
        <v-card-text>
          <v-text-field v-model="new_value" :error-messages="newValueMessage" />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn @click="onAddNewValue" rounded color="primary" small>
            Add
          </v-btn>
          <v-btn @click="addValueDialog = false" rounded small>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editValueDialog" width="500px" persistent>
      <v-card>
        <v-card-title style="color: #6e3dc6; font-family: 'Poppins-Bold'; "
          >Edit the Value</v-card-title
        >
        <v-card-text>
          <v-text-field v-model="new_value" :error-messages="newValueMessage" />
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn @click="onEditValue" rounded color="primary" small>
            Edit
          </v-btn>
          <v-btn @click="editValueDialog = false" rounded small>Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      values: [],
      selected: null,
      selectedValue: null,
      addSettingDialog: false,
      addValueDialog: false,
      editValueDialog: false,
      new_key: null,
      newKeyMessage: null,
      new_value: null,
      newValueMessage: null,
      isValueLoading: false,
    };
  },
  computed: {
    ...mapGetters("variable", {
      settings: "getAllSettings",
    }),
  },
  watch: {
    selected(value) {
      this.getValues({ _id: value })
        .then((values) => {
          this.loading = false;
          this.values = values;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
  },
  methods: {
    ...mapActions("variable", {
      fetchAllSettings: "fetchAllSettings",
      getValues: "getValues",
      addSetting: "addSetting",
      editSetting: "editSetting",
      deleteSetting: "deleteSetting",
      addValue: "addValue",
      editValue: "editValue",
      deleteValue: "deleteValue",
    }),
    onUpdate() {
      if (this.newPassword != this.confirmPassword) {
        this.errorMessage = "Password does not match";
        this.snackbar = true;
        return;
      }
      var params = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };

      this.loading = true;
      this.updatePassword(params)
        .then(() => {
          this.loading = false;
          this.showErrorMessage("Password updated successfully");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    openNewKeyDialog() {
      this.addSettingDialog = true;
      this.newKeyMessage = null;
      this.new_key = "";
    },
    openNewValueDialog() {
      this.addValueDialog = true;
      this.newValueMessage = null;
      this.new_value = "";
    },
    openEditValueDialog(value) {
      this.editValueDialog = true;
      this.newValueMessage = null;
      this.new_value = value.text;
    },
    onAddNewSetting() {
      if (!this.new_key) {
        this.newKeyMessage = "Required";
        return;
      }
      this.loading = true;
      this.newKeyMessage = null;
      this.addSetting({ title: this.new_key })
        .then(() => {
          this.loading = false;
          this.new_key = "";
          this.addSettingDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.newKeyMessage = error.response.data.message;
          }
        });
    },
    onAddNewValue() {
      if (!this.new_value) {
        this.newValueMessage = "Required";
        return;
      }
      this.isValueLoading = true;
      this.newValueMessage = null;
      this.addValue({ text: this.new_value, setting_id: this.selected })
        .then((value) => {
          this.isValueLoading = false;
          this.new_value = "";
          this.addValueDialog = false;
          this.values.push(value);
        })
        .catch((error) => {
          this.isValueLoading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.newValueMessage = error.response.data.message;
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onChange() {
      this.errorMessage = null;
    },
    onSelectKey(setting) {
      this.selected = setting._id;
    },
    onSelectValue(value) {
      this.selectedValue = value._id;
    },
    onDeleteKey(key) {
      this.deleteSetting({ _id: key._id })
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    onEditValue() {
      this.isValueLoading = true;
      this.editValue({ _id: this.selectedValue, text: this.new_value })
        .then((res) => {
          this.isValueLoading = false;
          this.values = this.values.map((v) => (v._id == res._id ? res : v));
          this.new_value = "";
          this.editValueDialog = false;
        })
        .catch((error) => {
          this.isValueLoading = false;
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    onDeleteValue(value) {
      this.deleteValue({ _id: value._id })
        .then(() => {
          this.loading = false;
          this.values = this.values.filter((v) => v._id != value._id);
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
  },
  mounted() {
    this.loading = true;
    this.fetchAllSettings()
      .then(() => {
        this.loading = false;
        if (this.settings && this.settings.length) {
          this.selected = this.settings[0]._id;
        }
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.key-item {
  height: 50px;
}
.key-item:hover {
  background: #ebf0f6;
}
.selected {
  background: #abafb3;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
